import { faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Input, message, PageHeader, Space, Table, Typography } from "antd";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Globalset from "./Globalset";

const { Title } = Typography;

function Search(){

    const nav = useNavigate();
    const [keywords, setKeywords] = useState('');
    const [lod, setLod] = useState(false);
    const [sudahmencari, setSudahmencari] = useState(false);
    const [sertifikat, setSertifikat] = useState([]);
    const sertifikat_column = [
        {title: 'Nama Lengkap', render: e=>( <b>{e['nama']}</b> )},
        {title: 'Pelatihan', dataIndex: 'kegiatan'},
        {title: 'Tempat', dataIndex: 'tempat'},
        {title: 'Tanggal', dataIndex: 'tanggal'},
        {title: 'Opsi', render: e=>(
            <Button type="primary"><Link to={'/view/'+e['token']}><FontAwesomeIcon icon={faEye} /> Lihat</Link></Button>
        )},
    ]
    const find = () => {
        setLod(true);
        axios(Globalset.app_url+'/user/search?keywords='+keywords, Globalset.app_authentic).then(res=>{
            setSudahmencari(true);
            if(res['status']===200){
                setSertifikat(res.data);
            } else {
                message.warning(res['request']['response']);
            }
            setLod(false);
        }).catch(e=>{
            setLod(false);
        });
    }

    return(
        <div className="container">
            <PageHeader title=' ' onBack={()=>{ nav(-1) }} />
            <Space direction="vertical" style={{width: '100%'}}>
                <Title level={2} >Pencarian Data Sertifikat</Title>
                <Input.Search allowClear placeholder="Isi Kode Token atau Nomor HP anda yang terdaftar ..." value={keywords} onChange={e=>{ setKeywords(e.target.value) }} />
                <Button loading={lod} type="primary" size="large" onClick={find}><> Cari <FontAwesomeIcon icon={faSearch} /> </> </Button>
                {sertifikat.length>0 ? <>
                <br /><br />
                Hasil Pencarian :
                <Table bordered dataSource={sertifikat} columns={sertifikat_column} />
                </> : null }
                < br/>
                {sudahmencari && sertifikat.length===0 ? <Alert type="warning" showIcon  message='Sertifikat tidak ditemukan !' /> : null }
            </Space>
            <Footer />
        </div>
    );
}

export default Search;
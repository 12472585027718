
import { useEffect, useState } from 'react';
import paket from '../package.json';



function Footer () {

    const [versi, setVersi] = useState(0);
    useEffect(()=>{
        setVersi(paket.version);
    }, []);

    return (
        <>
            <br /><br /><br /><br /><br /><br />
            <div className="d-flex">
                <div className="flex-fill">
                <b>Sertifikat</b> <small>ver.{versi}</small>
                </div>
                <div >
                PT. Indo Teknoplus © 2022
                </div>
            </div>
            <br />
        </>
    );
}

export default Footer;
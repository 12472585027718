import { Alert, Button, Card, Divider, message, Tag, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Globalset from "./Globalset";

const { Title } = Typography


function Home(){

    const [pelatihan, setPelatihan] = useState([]);
    const pelatihan_list = pelatihan.map(e=>(
        <Card className="m-2 shadow-sm" key={e['id']} >
            <Title level={5}><b><div dangerouslySetInnerHTML={{__html: e['nama'] }}></div></b></Title>
                {e['webinar']==='true' ?
                <div className="d-flex">
                    <div className="flex-fill">{e['tempat']}</div>    
                    <div><Tag color='green'>Webinar</Tag></div>
                </div> 
                :e['tempat'] }
            <br /><br />
            <Button type="primary"><Link to={'/register/'+e['id']}>PILIH</Link></Button>
        </Card>
    ));

    useEffect(()=>{
        axios(Globalset.app_url+'/events/active', Globalset.app_authentic).then(res=>{
            setPelatihan(res.data);
        }).catch(e=>{
            message.error('Server dalam perbaikan !');
        });
    }, []);

    return(
        <div className="container">
            <br /><br /><br />
            <Title level={1}>Registrasi Pelatihan</Title>
            <p>Untuk melihat data anda yang sudah terdaftar silahkan <Button type="dashed"><Link to='/search'><b>klik disini</b></Link></Button></p>
            <Divider dashed />

            {pelatihan.length>0 ?
            <>
            <p>Silahkan Pilih <b>Tempat Pelatihan</b> yang tersedia :</p>
            {pelatihan_list}
            </>: <Alert message='Hari ini tidak ada Pelatihan !' showIcon /> }
            <br /><br />
            
            <Footer />
        </div>
    );
}

export default Home;
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Register from "./Register";
import Search from "./Search";
import View from "./View";
import paket from '../package.json';
import axios from "axios";
import Globalset from "./Globalset";
import Update from "./Update";

function App(){

    const [versi, setVersi] = useState(0);
    const [terbaru, setTerbaru] = useState({});

    useEffect(()=>{
        setVersi(paket.version);

        axios.get(Globalset.app_url+'/settings/value?id=3', Globalset.app_authentic).then(res=>{
            setTerbaru(res.data);
        });
    }, []);

    return(
        <>
            {terbaru['nilai']>versi ? 
            <Routes>
                <Route path='*' element={<Update />} /> 
            </Routes>
            :
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/search' element={<Search />} />
                <Route path='/register/:id' element={<Register />} />
                <Route path='/view/:token' element={<View />} />
            </Routes>
            }
        </>
    );
}

export default App;

class Globalset {

    static app_name = 'Sertifikat - PT Indo Tekhnoplus';

    /*static app_url = 'http://localhost/sertifikat_api/public';
    static app_assets = 'http://localhost/sertifikat_api/public/assets';*/
    static app_url = 'https://sertifikat.indotekhnoplus.com';
    static app_assets = 'https://sertifikat.indotekhnoplus.com/assets';

    static app_primary = '#2ec4b6';

    static app_auth_user = 'afandi'
    static app_auth_pass = '@hm4d@FanDi!'
    static app_authentic = {auth: {username: this.app_auth_user, password: this.app_auth_pass}}

    /*
    lama
    static app_url = 'https://sertifikat.indotekhnoplus.com/api';
    static app_assets = 'https://sertifikat.indotekhnoplus.com/assets';
    static app_redir = 'https://sertifikat.indotekhnoplus.com/user/success'*/
}

export default Globalset;
import { faFilePdf, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Card, Descriptions, PageHeader, Space, Table } from "antd";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import Globalset from "./Globalset";
import { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./Footer";

function View(){
    
    const param = useParams();
    const [user, setUser] = useState({});
    const [produk, setProduk] = useState([]);
    const produk_colom = [
        {title: 'No.', render: (e,i,s)=>(s+1)},
        {title: 'Pelatihan Untuk Produk', dataIndex: 'produk'},
        {title: 'Tipe', dataIndex: 'tipe'},
        {title: 'Merk', dataIndex: 'brand'},
    ]
    const [token, setToken] = useState('');

    useEffect(()=>{
        axios(Globalset.app_url+'/user/info_by_token?token='+param.token, Globalset.app_authentic).then(res=>{
            //console.log(res.data);
            if(res.data!=='access denied !')
            {
                axios(Globalset.app_url+'/events/product?id='+res.data['idkegiatan'],  Globalset.app_authentic).then(res2=>{
                    if(res2.data!='access denied !')
                    {
                        setUser(res.data);
                        setToken(res.data.token.toUpperCase());
                        setProduk(res2.data);
                    }
                });
            }
        });
    }, []);
    
    return(
        <div className="container">
            <br /><br />
            <center>
            <FontAwesomeIcon icon={faUserGraduate} size='4x' color={Globalset.app_primary}/>
            <br /><br />
            <PageHeader title='Informasi Peserta' className="shadow-sm" />
            <br /><br />
            <QRCode value={Globalset.app_url+'/user/view/'+param.token} />
            <br />
            *anda bisa akses melalui HP Smartphone dengan Scan QR Code diatas
            </center>
            <br /><br />
            {user['valid'] ==='true' ? <Alert showIcon message={'Status terverifikasi dengan nomor : ' + user['no'] } /> : <Alert showIcon type="warning" message='Status belum terverifikasi' /> }
            <br />
            <Space direction="vertical">
                <Card>
                Kode Token : <b>{ token }</b>
                <br />
                *Harap catat kode token ini jika anda ingin mengambil data/cetak Sertifikat melalui website <a href={Globalset.app_url}>{Globalset.app_url}</a>
                </Card>
                
                <Card title='Peserta'>
                    <Descriptions>
                        <Descriptions.Item label='Nama Peserta'><b>{user['nama']}</b></Descriptions.Item>
                        <Descriptions.Item label='Kota/Kabupaten'><b>{user['kotkab']}</b></Descriptions.Item>
                        <Descriptions.Item label='Instansi'><b>{user['instansi']}</b></Descriptions.Item>
                        <Descriptions.Item label='Jabatan'><b>{user['jabatan']}</b></Descriptions.Item>
                        <Descriptions.Item label='Dalam Pelatihan'><b>{user['kegiatan']}</b></Descriptions.Item>
                        <Descriptions.Item label='Tempat Pelatihan'><b>{user['tempat']}</b></Descriptions.Item>
                        <Descriptions.Item label='Diselenggarakan Tanggal'><b>{user['tanggal']}</b></Descriptions.Item>
                        {user['tanggal_hingga']!=='0000-00-00' ?
                        <Descriptions.Item label='Sampai Tanggal'><b>{user['tanggal_hingga']}</b></Descriptions.Item>
                        :null}
                    </Descriptions>
                </Card>
                <Table dataSource={produk} columns={produk_colom} size='small' pagination={false} />
                <br />
                <Button type='primary' size="large"><a target='_blank' href={Globalset.app_url+'/user/pdf/'+user['token']}><FontAwesomeIcon icon={faFilePdf} /> Lihat Sertifikat</a></Button>
            </Space>
            <Footer />
        </div>
    );
}

export default View;
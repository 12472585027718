import { Alert, Button, Space } from "antd";
import Globalset from "./Globalset";

function Update(){
    return(
        <div className="container mt-3">
            <h2>{Globalset.app_name}</h2>
            <br />
            <Space direction="vertical" className="w-100">
                <Alert message='Silahkan refresh untuk web terbaru !' />
                <Button type="primary" onClick={()=>{ window.location.reload() }}>Reload</Button>
            </Space>
        </div>
    );
}

export default Update;
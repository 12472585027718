import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSignature, faHandPointer, faImage, faSave } from "@fortawesome/free-solid-svg-icons";
import { AutoComplete, Button, Card, Checkbox, Upload, Form, Image, Input, Layout, message, Space, Table, Tag, Modal, Divider, Radio, Typography } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import Globalset from "./Globalset";
import { serialize } from "object-to-formdata";
import { useNavigate, useParams } from "react-router-dom";

const { Content } = Layout;
const { Title } = Typography;

function Register(){

    const [diizinkan, setDiizinkan] = useState(true);
    const [webinar, setWebinar] = useState(true);
    const [kegiatan, setKegiatan] = useState({});
    const [calat, setCalat] = useState(false);
    const [creagen, setCreagen] = useState(false);
    const [ckalibrasi, setCkalibrasi] = useState(false);
    const [kalat, setKalat] = useState('');
    const [kreagen, setKreagen] = useState('');
    const [kkalibrasi, setKkalibrasi] = useState('');

    const [lod, setLod] = useState(false);
    const [instansi, setInstansi] = useState([]);
    const [idinstansi, setIdinstansi] = useState(0);
    const [galeri, setGaleri] = useState([]);
    const galeri_list = galeri.map(e=>( <Image key={e['id']} style={{padding: 2}} height={128} src={Globalset.app_assets+'/gallery/512/'+e} preview={{ src: Globalset.app_assets+'/gallery/1280/'+e}} /> ));

    const [modgaleri, setModgaleri] = useState(false);
    const [filename, setFilename] = useState('');
    const param = useParams();
    const [peserta, setPeserta] = useState([]);
    const peserta_kolom = [
        {title: 'Urut', render: (e,i,s) => ( s+1 )},
        {title: 'Nama Peserta', dataIndex: 'nama'},
        {title: 'Instansi', dataIndex: 'instansi'},
        {title: 'Terdaftar', dataIndex: 'tercatat'}
    ]

    const nav = useNavigate();
    const [bermanfaat, setBermanfaat] = useState(true);
    const [setuju, setSetuju] = useState(true);

    const find = e => {
        axios(Globalset.app_url+'/instance?keywords='+e, Globalset.app_authentic).then(res=>{
            let json = res.data;
            for (let a=0; a<json.length; a++){
                //json[a]['label'] = json[a]['nama'];
                json[a]['value'] = json[a]['nama'];
            }   
            setInstansi(res.data);
            setIdinstansi(0);
        });
    } 

    const finish = e => {
        e['idinstansi'] = idinstansi;
        e['alat'] = calat;
        e['alat_keterangan'] = kalat;
        e['reagen'] = creagen;
        e['reagen_keterangan'] = kreagen;
        e['kalibrasi'] = ckalibrasi;
        e['kalibrasi_keterangan'] = kkalibrasi;
        e['idkegiatan'] = param.id;
        e['setuju'] = setuju;
        e['bermanfaat'] = bermanfaat;

        if(e['materi']===undefined){
            e['materi'] = '';
        }
        if(e['saran']===undefined){
            e['saran'] = '';
        }
        
        setLod(true);
        axios.post(Globalset.app_url+'/user/add', serialize(e), Globalset.app_authentic).then(res=>{
            let json = res.data;
            if(json['status']===1){
                message.success('Pendaftaran berhasil !');
                setTimeout(()=>{
                    nav('/view/'+json['token']);
                }, 1000);
            } else {
                message.error('Terjadi gangguan !');
            }
            
        }).catch(()=>{
            setLod(false);
            message.error('Koneksi internet eror !');
        });
    }

    const fillpeserta = () => {
        axios(Globalset.app_url+'/events/user?id='+param.id, Globalset.app_authentic).then(res=>{
            if(res.data!=='access denied !'){
                setPeserta(res.data);
            }
            
        });
    }

    const fillkegiatan = () => {
        axios(Globalset.app_url+'/events/info?id='+param.id, Globalset.app_authentic).then(res=>{
            let json = res.data;
            setKegiatan(json);
            if(json['aktif']==='false'){
                setDiizinkan(false);
            }
            if(json['webinar']==='false')
            {
                setWebinar(false);
                if(json['foto']!==''){
                    let imgs = json['foto'].split(';');
                    if(imgs[0]!==''){
                        setGaleri(imgs);
                    } else {
                        setGaleri([]);
                    }
                } else {
                    setGaleri([]);
                }
            } 
            fillpeserta();
        });
    }

    const props = {
        name: 'userfile',
        action: Globalset.app_url+'/home/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
            //console.log(info.file.response);
            setFilename(info.file.response);
            }
            if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const [modlod, setModlod] = useState(false);
    const finmod = () => {
        if(filename!==''){
            let data = {
                id: param.id,
                filename: filename
            }
            setModlod(true);
            axios.post(Globalset.app_url+'/user/image', serialize(data), Globalset.app_authentic).then(res=>{
                setModlod(false);
                message.success('Unggah berhasil !');
                fillkegiatan();
                setFilename('');
                setModgaleri(false);
                //console.log(res.data);
            }).catch(()=>{
                message.error('Server eror !');
            });
        } else {
            message.error('Foto belum dipilih !');
        }
    }

    useEffect(()=>{
        fillkegiatan();
        //fillpeserta();
    }, []);

    return(
        <Layout style={{backgroundColor: Globalset.app_primary}}>
            {diizinkan ?
            <Content>
                <div className="container">
                    <br />

                    <Title level={2} style={{color: 'white'}}>{kegiatan['tempat']}</Title>
                 
                    <h6 style={{color: 'white'}}><div dangerouslySetInnerHTML={{__html: kegiatan['nama']}}></div></h6>

                    <Space direction="vertical" style={{width: '100%'}}>
                        <Card title='Pendaftaran Peserta Baru' extra={<Tag color='green'>Tanggal : {kegiatan['tanggal']}</Tag>}>
                            <Form layout='vertical' onFinish={finish} >
                                <Form.Item label='Nama Peserta' name='nama' extra='*Nama yang diisi akan sesuai yang tertera pada Sertifikat dan tidak dapt dirubah kembali, mohon diisi dengan lengkap, benar dan jelas' rules={[{required: true, message: 'Nama Peserta dibutuhkan untuk penamaan di Sertifikat'}]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label='Kota/Kabupaten' name='kotkab' rules={[{required: true, message: 'Kota/Kabupaten dibutuhkan'}]}>
                                    <Input />
                                </Form.Item>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Item label='Nama Instansi' name='instansi' rules={[{required: true, message: 'Nama Instansi dibutuhkan'}]}>
                                            <AutoComplete options={instansi} onSearch={e=>{ find(e) }} onSelect={(e, i)=>{ setIdinstansi(i['id']); }} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Item label='Jabatan' name='jabatan' rules={[{required: true, message: 'Jabatan dibutuhkan'}]}>
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <Form.Item label='Nomor HP' name='telp' rules={[{required: true, message: 'Nomor HP tidak boleh kosong'}]} extra='*Hp digunakan untuk pencarian sertifikat anda' >
                                            <Input type='tel' placeholder="contoh: 08XXXX...." />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <Form.Item label='Email' name='email' rules={[{required: true, message: 'Email tidak boleh kosong'}]} extra='*Email digunakan untuk memulihkan kode Token Sertifikat jika anda lupa'>
                                            <Input type='email' />
                                        </Form.Item>
                                    </div>
                                </div>

                                {webinar ?
                                    
                                    <>
                                        <Form.Item label='Rekomendasi/Kebutuhan Alat' extra='*Mohon diceklis sesuai kebutuhan'>
                                            <div className="d-flex mb-2">
                                                <Checkbox checked={calat} onChange={(e)=>{ setCalat(e.target.checked); setKalat(''); }}>Alat</Checkbox>
                                                {calat ? <Input size="small" placeholder="Keterangan Alat" value={kalat} onChange={e=>{ setKalat(e.target.value) }} /> : <Input size="small" disabled placeholder="Keterangan Alat" /> }
                                            </div>
                                            <div className="d-flex mb-2">
                                                <Checkbox checked={creagen} onChange={(e)=>{ setCreagen(e.target.checked); setKreagen(''); }}>Reagen</Checkbox>
                                                {creagen ? <Input size="small" placeholder="Keterangan Reagen" value={kreagen} onChange={e=>{ setKreagen(e.target.value) }} /> : <Input size="small" disabled placeholder="Keterangan Reagen" /> }
                                            </div>
                                            <div className="d-flex mb-2">
                                                <Checkbox checked={ckalibrasi} onChange={(e)=>{ setCkalibrasi(e.target.checked); setKkalibrasi(''); }}>Kalibrasi</Checkbox>
                                                {ckalibrasi ? <Input size="small" placeholder="Keterangan Kalibrasi" value={kkalibrasi} onChange={e=>{ setKkalibrasi(e.target.value) }} /> : <Input size="small" disabled placeholder="Keterangan Kalibrasi" /> }
                                            </div>
                                        </Form.Item>
                                        <Divider dashed />
                                        <Form.Item label='Apakah Pelatihan ini bermanfaat untuk Bapak/Ibu ?'>
                                            <Radio.Group value={bermanfaat}  onChange={e=>{ setBermanfaat(e.target.value) }}>
                                                <Radio value={true}>Ya</Radio>
                                                <Radio value={false}>Tidak</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item label='Apakah setuju Pelatihan ini diadakan kembali dikemudian hari ?'>
                                            <Radio.Group value={setuju}  onChange={e=>{ setSetuju(e.target.value) }}>
                                                <Radio value={true}>Ya Setuju</Radio>
                                                <Radio value={false}>Tidak Setuju</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        {setuju ?
                                        <Form.Item label='Jika setuju untuk Pelatihan berikutnya materi/alat apa yang disarankan ?' name='materi' rules={[{required: true, message: 'Materi/alat saran anda diperlukan'}]}>
                                            <Input />
                                        </Form.Item>
                                        : null }
                                        <Form.Item label='Kritik dan saran dalam Pelatihan ini' name='saran'>
                                            <Input.TextArea />
                                        </Form.Item>
                                    </>

                                    : null }

                                <Form.Item>
                                    <Button htmlType="submit" loading={lod} size="large" type="primary"><><FontAwesomeIcon icon={faFileSignature} /> Daftar</></Button>
                                </Form.Item>
                                
                            </Form>
                        </Card>

                        {webinar===false ?
                        <Card title='Galeri'>
                            {galeri_list}
                            <br /><br />
                            <Button onClick={()=>{ setModgaleri(true) }}><><FontAwesomeIcon icon={faImage} /> Unggah Foto</></Button>
                            <Modal visible={modgaleri} title='Tambahkan Foto Baru' footer={null} onCancel={()=>{ setModgaleri(false) }} >
                                <Form>
                                    <Form.Item>
                                        <Upload {...props} maxCount={1}>
                                            <Button><><FontAwesomeIcon icon={faHandPointer} /> Pilih Foto</></Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item className="text-end">
                                        <Button onClick={finmod} type="primary" loading={modlod}><><FontAwesomeIcon icon={faSave} /> Simpan</></Button>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </Card>
                        :null }

                        <Card title={ 'Peserta yang Terdaftar di ' +  kegiatan['tempat']}>
                            <Table bordered size="small" pagination={false} dataSource={peserta} columns={peserta_kolom} />
                        </Card>
                    </Space>
                    <br /><br />
                </div>
            </Content> :
            <Content style={{height: '100vh', textAlign: 'center', color: 'white' }}>
                <br /><br /><br />
                Mohon Maaf
                <Title level={3} style={{color: 'white'}}>Pelatihan Tidak Tersedia !</Title>
                <Divider />
            </Content> }
        </Layout>
    );
}

export default Register;